<template>
  <pt-popup v-model="popupOpened" wide paddingless>
    <div class="triage-tool-popup">
      <triage-tool class="triage-tool-popup__tool" @close="closePopup" />
      <div class="triage-tool-popup__blob">
        <svg width="1014" height="843" viewBox="0 0 1014 843" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M977.812 361.857C954.34 439.014 885.374 494.746 827.235 557.687C771.336 618.204 721.306 679.317 645.364 721.767C544.522 778.134 440.165 856.665 320.448 840.965C197.951 824.9 101.975 735.3 44.7807 643.003C-8.14299 557.598 24.3336 458.114 23.5554 361.857C22.7701 264.723 -38.7326 148.974 40.212 77.9251C120.386 5.76974 265.064 54.719 381.302 39.8888C472.06 28.3095 554.316 -5.06273 645.864 0.652932C761.719 7.88616 902.564 -0.329862 973.504 76.862C1044.38 153.986 1006.52 267.493 977.812 361.857Z"
            fill="#E8F4EE"
          />
        </svg>
      </div>
    </div>
  </pt-popup>
</template>

<script lang="ts" setup>
import TriageTool from '~/components/global/components/triage-tool/TriageTool.vue';
import { TRIAGE_POPUP_SLUG } from '~/constants/popups';
import { useTriageToolStore } from '~/stores/triage-tool';
import { usePopups } from '~/composables/popup';
import PtPopup from '~/components/global/elements/PtPopup.vue';

const { reset } = useTriageToolStore();
const { popupOpened, closePopup } = usePopups(TRIAGE_POPUP_SLUG);

watch(popupOpened, reset);
</script>
