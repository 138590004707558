<template>
  <div class="app-layout site-wrapper">
    <div class="wrap">
      <app-header v-if="!isDigitalScreenPath(route.path)" />
      <div class="app-layout__main main">
        <slot />
      </div>
      <app-footer v-if="!isDigitalScreenPath(route.path)" />
      <app-announcement-bar />
      <book-appointment-form-popup />
      <contact-form-popup />
      <featured-video-popup />
      <timeline-popup />
      <triage-tool-popup />
      <video-embed-popup />
      <zoomable-image-popup />
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppAnnouncementBar from '~/components/ui/layout/AppAnnouncementBar.vue';
import AppHeader from '~/components/ui/layout/AppHeader.vue';
import AppFooter from '~/components/ui/layout/AppFooter.vue';
import BookAppointmentFormPopup from '~/components/global/components/forms/BookAppointmentFormPopup.vue';
import ContactFormPopup from '~/components/global/components/forms/ContactFormPopup.vue';
import FeaturedVideoPopup from '~/components/global/components/featured/FeaturedVideoPopup.vue';
import TimelinePopup from '~/components/global/components/timeline/TimelinePopup.vue';
import TriageToolPopup from '~/components/global/components/triage-tool/TriageToolPopup.vue';
import VideoEmbedPopup from '~/components/global/videos/VideoEmbedPopup.vue';
import ZoomableImagePopup from '~/components/global/images/ZoomableImagePopup.vue';

const route = useRoute();
const { isDigitalScreenPath } = useDigitalScreen();
</script>
