<template>
  <nav :class="['footer-nav', typeClass]">
    <ul>
      <footer-nav-item v-for="item in items" :key="`nav-item-${item.id}`" :item="item" />
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import FooterNavItem from '~/components/ui/layout/footer-nav/FooterNavItem.vue';

const props = defineProps({
  items: {
    type: Array as () => { id: number; name: string; link: string }[],
    required: true,
  },
  type: {
    type: String,
    default: 'primary',
  },
});

const typeClass = computed(() => {
  return `is-${props.type}`;
});
</script>
