<template>
  <button class="header-nav-toggler" aria-label="Mobile menu toggle" @click.prevent="toggleNavOverlay">
    <span class="header-nav-toggler__label">{{ navOverlayOpen ? 'Close' : 'Menu' }}</span>
    <span class="header-nav-toggler__icon">
      <pt-icon v-if="navOverlayOpen" name="close-bold" :size="18" aria-hidden="false" />
      <pt-icon v-else name="hamburger" :size="24" aria-hidden="false" />
    </span>
  </button>
</template>

<script setup lang="ts">
import PtIcon from '~/components/global/elements/PtIcon.vue';

const navStore = useLayoutNavStore();
const navOverlayOpen = computed(() => navStore.navOverlayOpen);

function toggleNavOverlay() {
  navStore.toggleNavOverlay(!navOverlayOpen.value);
}
</script>
