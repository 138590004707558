<template>
  <div class="header-search-bar">
    <div class="header-search-bar__inner" role="search">
      <form class="header-search-bar__form" role="form" @submit.prevent="handleSearch">
        <label class="visually-hidden" for="header-search-input">Search</label>
        <input
          id="header-search-input"
          ref="searchInput"
          class="header-search-bar__input hide-clear"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="none"
          spellcheck="false"
          aria-label="Search"
          placeholder="Search the Public Trust site..."
          type="search"
        />
        <button class="header-search-bar__submit" type="submit" aria-label="Search button">
          <pt-icon name="search" :size="20" />
        </button>
        <button class="header-search-bar__close" type="button" aria-label="Close search bar" @click="close">
          <pt-icon name="close-bold" :size="12" />
        </button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { SEARCH_PATH } from '@/constants/search';
import PtIcon from '~/components/global/elements/PtIcon.vue';

const { toggleNavOverlay } = useLayoutNavStore();
const router = useRouter();
const searchInput = ref<HTMLInputElement | null>(null);
const emit = defineEmits(['close']);

function handleSearch() {
  const query = searchInput.value?.value;
  if (query) {
    router.push({ path: SEARCH_PATH, query: { query } });
    close();
    toggleNavOverlay(false);
  }
}

watch(searchInput, (val) => {
  if (val) {
    searchInput.value?.focus();
  }
});

function close() {
  emit('close');
}
</script>
