<template>
  <nav class="main-nav" aria-label="Main Navigation">
    <ul class="main-nav__menu" role="menubar">
      <MegaMenuRootItem v-for="(rootItem, index) in nav" :key="`root-item-${index}`" :item="rootItem" :route="route" />
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import MegaMenuRootItem from '~/components/ui/layout/mega-menu/MegaMenuRootItem.vue';

const route = useRoute();

defineProps({
  nav: {
    type: Array,
    required: true,
  },
});
</script>
