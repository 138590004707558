<template>
  <li
    v-click-outside="onClickOutside"
    class="main-nav__item"
    role="none"
    :class="{
      'has-sub': hasChildren,
      'is-hovered': isVisible,
      'is-mobile-only': displayMobileOnly,
    }"
    @keydown.esc="close"
  >
    <MegaMenuItemLink
      class="main-nav__link"
      label-class="main-nav__link-label"
      post-icon-class="main-nav__link-icon"
      :pre-icon="icon"
      :item="item"
      :has-drop="hasChildren"
      :aria-haspopup="hasChildren"
      :aria-expanded="isVisible"
      :route="route"
      role="menuitem"
      tabindex="0"
      @link-click="onParentClick"
    />
    <template v-if="hasChildren">
      <transition name="fade">
        <MegaMenuItemDrop v-show="isVisible" :item="item" class="main-nav__sub" @link-click="onLinkClick" @close="close" />
      </transition>
    </template>
  </li>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { getSelectValue } from '@/utils/blocks';
import { useLayoutNavStore } from '@/stores/layout-nav';
import { clickOutside } from '~/directives/click-outside';
import MegaMenuItemDrop from '~/components/ui/layout/mega-menu/MegaMenuItemDrop.vue';
import MegaMenuItemLink from '~/components/ui/layout/mega-menu/MegaMenuItemLink.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  mobileOnly: {
    type: Boolean,
    default: false,
  },
  preIcon: {
    type: String,
    default: null,
  },
  route: {
    type: Object,
    default: null,
  },
});

const isVisible = ref(false);
const layoutNavStore = useLayoutNavStore();
const hasChildren = computed(() => {
  return Array.isArray(props.item.child_links) && props.item.child_links.length > 0;
});

const displayMobileOnly = computed(() => {
  return props.mobileOnly || props.item.mobile_only;
});

const icon = computed(() => {
  return props.preIcon || getSelectValue(props.item.mobile_icon, 'slug');
});

watch(isVisible, (val) => {
  if (val || layoutNavStore.openedNavItem !== props.item) {
    document.documentElement.classList.add('is-nav-over');
  } else {
    document.documentElement.classList.remove('is-nav-over');
  }
});

const onLinkClick = () => {
  close();
};

const vClickOutside = clickOutside;

const onParentClick = (ev) => {
  if (hasChildren.value) {
    ev.preventDefault();
    isVisible.value = !isVisible.value;
    layoutNavStore.toggleOpenedNavItem(props.item);
  } else {
    layoutNavStore.toggleNavOverlay(false);
  }
};

const onClickOutside = () => {
  if (hasChildren.value && isVisible.value) {
    isVisible.value = false;
  }
};

const close = () => {
  isVisible.value = false;

  if (layoutNavStore.navOverlayOpen) {
    layoutNavStore.toggleNavOverlay(false);
  }
};
</script>
