<template>
  <div>
    <svg width="843" height="1014" viewBox="0 0 843 1014" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M481.143 977.812C403.986 954.34 348.254 885.374 285.313 827.235C224.796 771.336 163.683 721.306 121.233 645.363C64.8658 544.522 -13.6653 440.165 2.03504 320.448C18.1 197.951 107.7 101.975 199.997 44.7806C285.402 -8.14305 384.887 24.3336 481.143 23.5553C578.277 22.77 694.026 -38.7326 765.075 40.212C837.23 120.386 788.281 265.064 803.111 381.302C814.691 472.06 848.063 554.316 842.347 645.864C835.114 761.719 843.33 902.564 766.138 973.504C689.014 1044.38 575.507 1006.52 481.143 977.812Z"
        fill="#E8F4EE"
      />
    </svg>
  </div>
</template>
