<template>
  <pt-popup v-model="popupOpened" :show-close-button-text="false" paddingless wide>
    <div v-if="currentVideo" class="featured-video-popup">
      <div class="featured-video-popup__embed">
        <embed-block v-bind="embedBindings" />
      </div>
      <div class="featured-video-popup__info">
        <div class="featured-video-popup__info-content">
          <h3 class="title is-3">
            {{ currentVideo.title }}
          </h3>
          <bard v-if="currentVideo.full_description" :as-content="true" :content="currentVideo.full_description" />
        </div>

        <div v-if="buttons.length" class="featured-video-popup__buttons">
          <pt-buttons>
            <pt-button
              v-for="(button, index) in buttons"
              :key="`button-${index}`"
              v-bind="getButtonBindings(button)"
              class="featured-video-popup__button"
            >
              {{ button.label || 'Learn More' }}
            </pt-button>
          </pt-buttons>
        </div>
      </div>
    </div>
  </pt-popup>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { FEATURED_VIDEO_POPUP } from '@/constants/popups';
import { getSectionButtons } from '@/utils/blocks';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import { useFeaturedVideoStore } from '~/stores/featured-video';
import EmbedBlock from '~/components/global/components/embed/EmbedBlock.vue';
import Bard from '~/components/content/Bard.vue';
import PtPopup from '~/components/global/elements/PtPopup.vue';
import { usePopups } from '~/composables/popup';

const { getButtonBindings } = useButtons();
const featuredVideoStore = useFeaturedVideoStore();
const { currentVideo } = storeToRefs(featuredVideoStore);
const { popupOpened } = usePopups(FEATURED_VIDEO_POPUP);
const embedBindings = computed(() => ({
  type: 'video',
  code: currentVideo?.value.embed_url,
}));
const buttons = computed(() => getSectionButtons(currentVideo?.value.buttons));
</script>
