<template>
  <pt-popup v-model="popupOpened" paddingless wide>
    <div class="book-apt-popup">
      <div class="book-apt-popup__cols">
        <div class="book-apt-popup__col is-details">
          <div class="book-apt-popup__details">
            <h3 class="book-apt-popup__details-title title is-1">Contact Public&nbsp;Trust</h3>
            <div v-if="contactPhone" class="book-apt-popup__details-contact has-marg-top-l">
              <a :href="contactPhoneHref" class="book-apt-popup__details-contact-link lead-label is-strong">
                <pt-icon name="phone" :size="18" />
                <span>
                  {{ contactPhone }}
                </span>
              </a>
            </div>
            <div class="book-apt-popup__details-contact has-marg-top-xxs">
              <pt-link-action action="book" class="book-apt-popup__details-contact-link lead-label is-strong">
                <pt-icon name="calendar-3" :size="18" />
                <span> Book Online </span>
              </pt-link-action>
            </div>
            <div class="book-apt-popup__details-image has-marg-top-xl">
              <figure>
                <img src="/images/book-now.png" alt="Book Now Image" />
              </figure>
            </div>
          </div>
          <popup-book-blob class="book-apt-popup__blob" />
        </div>
        <div class="book-apt-popup__col is-form">
          <div class="book-apt-popup__form">
            <div v-if="isSubmitted" class="book-apt-popup__success">
              <p>
                Thank you for your contact details.
                <br />
                We'll be in touch shortly.
              </p>
            </div>
            <marketo-form v-else :uniq-id="contactFormUniqId" :form-id="contactFormId" @success="onSuccess" />
          </div>
        </div>
      </div>
    </div>
  </pt-popup>
</template>

<script lang="ts" setup>
import { useGtm } from '@gtm-support/vue-gtm';
import { CONTACT_POPUP_SLUG } from '@/constants/popups';
import { usePopups } from '~/composables/popup';
import { useGlobalsStore } from '~/stores/globals';
import PopupBookBlob from '~/components/global/blobs/PopupBookBlob.vue';
import PtIcon from '~/components/global/elements/PtIcon.vue';
import { formatPhoneHref } from '~/utils/formatters';
import PtLinkAction from '~/components/global/elements/PtLinkAction.vue';
import MarketoForm from '~/components/global/forms/MarketoForm.vue';
import PtPopup from '~/components/global/elements/PtPopup.vue';

const { popupOpened } = usePopups(CONTACT_POPUP_SLUG);
const successEventName = 'contact-form-submit';

const globals = useGlobalsStore();
const contactPhone = computed(() => globals.contact.phone || null);
const contactPhoneHref = computed(() => formatPhoneHref(contactPhone.value));

const isSubmitted = ref(false);

const contactFormId = computed(() => globals.contact.marketo_form_id || null);
const contactFormUniqId = computed(() => globals.contact.marketo_uniq_id || 1112);

const gtm = useGtm();

const onSuccess = () => {
  isSubmitted.value = true;
  if (gtm) {
    gtm.push({ event: successEventName });
  }
};
</script>
