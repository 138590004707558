<template>
  <pt-popup ref="timelinePopup" v-model="popupOpened" class="timeline-popup" paddingless middle :close-button-animation="false">
    <div v-if="currentEvent" class="timeline-popup__inner">
      <div class="timeline-popup__images">
        <div v-if="currentEvent.popup_gallery && currentEvent.popup_gallery.length > 1" class="timeline-popup__slider">
          <client-only>
            <swiper
              :slides-per-view="1"
              :space-between="0"
              :loop="false"
              :navigation="{
                prevEl: prevBtnRef,
                nextEl: nextBtnRef,
              }"
              :modules="[Navigation]"
            >
              <swiper-slide v-for="image in currentEvent.popup_gallery" :key="image.id" class="block-history-timeline__slide">
                <div>
                  <picture-basic
                    v-bind="
                      getPictureBindings(image, {
                        contain: true,
                        ratio: 4 / 4,
                        displayCaption: true,
                        displayCredit: true,
                      })
                    "
                  />
                </div>
              </swiper-slide>
            </swiper>
            <div class="timeline-popup__slider-arrows">
              <button ref="prevBtnRef" class="timeline-popup__slider-arrow is-prev popup-button-prev" aria-label="Previous">
                <pt-icon-chevron-right />
              </button>
              <button ref="nextBtnRef" class="timeline-popup__slider-arrow is-next popup-button-next" aria-label="Next">
                <pt-icon-chevron-right />
              </button>
            </div>
          </client-only>
        </div>
        <div v-else class="timeline-popup__single-image">
          <picture-basic
            v-bind="
              getPictureBindings(singleImage, {
                contain: true,
                ratio: 4 / 4,
                displayCaption: true,
                displayCredit: true,
              })
            "
          />
        </div>
      </div>
      <transition name="fade">
        <div v-if="!loading" class="timeline-popup__content has-scroll">
          <div>
            <!-- do not remove, justify space between centering trick-->
          </div>
          <div>
            <h5 class="timeline-popup__content-title">{{ currentEvent.title }}, {{ currentEvent.year }}</h5>
            <bard class="timeline-popup__content-full" :content="currentEvent.popup_content" as-content />
          </div>
          <div>
            <!-- do not remove, justify space between centering trick-->
          </div>
        </div>
      </transition>
    </div>
    <div class="timeline-popup__footer">
      <button
        type="button"
        class="timeline-popup__arrow is-prev"
        :class="{
          'is-disabled': !canPrev(currentEventIndex),
        }"
        :disabled="!canPrev(currentEventIndex)"
        @click="onPrevEvent"
      >
        <span class="timeline-popup__arrow-icon">
          <pt-icon-chevron-right />
        </span>
        <span class="timeline-popup__arrow-text"> Previous Milestone </span>
      </button>
      <button
        type="button"
        class="timeline-popup__arrow is-next"
        :class="{
          'is-disabled': !canNext(currentEventIndex),
        }"
        :disabled="!canNext(currentEventIndex)"
        @click="onNextEvent"
      >
        <span class="timeline-popup__arrow-text"> Next Milestone </span>
        <span class="timeline-popup__arrow-icon">
          <pt-icon-chevron-right />
        </span>
      </button>
    </div>
  </pt-popup>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import { TIME_LINE_POPUP } from '~/constants/popups';
import Bard from '~/components/content/Bard.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import PtIconChevronRight from '~/components/global/icons/PtIconChevronRight.vue';
import PtPopup from '~/components/global/elements/PtPopup.vue';
import { useImages } from '~/composables/images';
import { usePopups } from '~/composables/popup';

const timelinePopup = ref(null);
const prevBtnRef = ref(null);
const nextBtnRef = ref(null);
const { popupOpened } = usePopups(TIME_LINE_POPUP);
const { getPictureBindings } = useImages();
const timelineStore = useTimelineStore();
const { currentEvent } = storeToRefs(timelineStore);
const loading = ref(false);
const currentEventIndex = ref(0);
const singleImage = computed(() => {
  if (currentEvent.value?.popup_gallery?.length === 1) {
    return currentEvent.value.popup_gallery[0];
  } else {
    return currentEvent.value?.featured_image;
  }
});

watch(currentEvent, (currentEvent) => {
  if (timelinePopup.value?.content) {
    timelinePopup.value.content?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  if (currentEvent) {
    currentEventIndex.value = timelineStore.events.findIndex((event) => event.id === currentEvent.id);
  }
});

const getEvent = (type) => {
  loading.value = true;
  let eventIndex = timelineStore.events?.findIndex((event) => event.id === currentEvent.value?.id);

  if (type === 'next' && eventIndex < timelineStore.events.length - 1) {
    eventIndex += 1;
  } else if (type === 'prev' && eventIndex > 0) {
    eventIndex -= 1;
  }

  setTimeout(() => {
    loading.value = false;
  }, 50);

  return timelineStore.events[eventIndex];
};

const onNextEvent = () => {
  timelineStore.setCurrentEvent(getEvent('next'));
};

const onPrevEvent = () => {
  timelineStore.setCurrentEvent(getEvent('prev'));
};

const canPrev = (index: number) => {
  return index > 0;
};

const canNext = (index: number) => {
  return timelineStore.events.length - 1 > index;
};
</script>
