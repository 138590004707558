<template>
  <div v-if="loginUrl" class="header-extras-sec">
    <PtButton type="primary" expanded tag="a" :href="loginUrl" target="_blank" rel="external"> Login </PtButton>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useGlobalsStore } from '~/stores/globals';
import PtButton from '~/components/global/elements/PtButton.vue';

const globals = useGlobalsStore();
const loginUrl = computed(() => globals.loginUrl);
</script>
