<template>
  <div class="header-extras">
    <div class="header-extras__inner">
      <div v-if="contactPhone" class="header-extras__item is-phone">
        <a :href="contactPhoneHref" class="header-extras__phone">
          {{ contactPhone }}
        </a>
      </div>
      <div v-if="loginUrl" class="header-extras__item is-login">
        <pt-button type="primary" size="small" tag="a" :href="loginUrl" target="_blank" rel="external"> Log in </pt-button>
      </div>
      <div class="header-extras__item is-locations">
        <app-link class="header-extras__locations" aria-label="Locations" to="/locations/">
          <pt-icon name="pin" :size="20" />
        </app-link>
      </div>
      <div class="header-extras__item is-search">
        <transition name="fade">
          <header-search-bar v-if="isSearchBarVisible" @close="closeSearchBar" />
        </transition>
        <button class="header-extras__search-toggle" aria-label="Open search bar" @click="openSearchBar">
          <pt-icon name="search" :size="20" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { formatPhoneHref } from '~/utils/formatters';
import HeaderSearchBar from '~/components/global/components/search/HeaderSearchBar.vue';
import PtIcon from '~/components/global/elements/PtIcon.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import AppLink from '~/components/AppLink';
import { useGlobalsStore } from '~/stores/globals';

const globals = useGlobalsStore();
const contactPhone = computed(() => globals.contactPhone);
const loginUrl = computed(() => globals.loginUrl);
const isSearchBarToggledOn = ref(false);
const isTouch = ref(false);

const isSearchBarVisible = computed(() => isSearchBarToggledOn.value || isTouch.value);
const contactPhoneHref = computed(() => formatPhoneHref(contactPhone.value));

function onResize() {
  closeSearchBar();
  isTouch.value = window.innerWidth <= 768; // Example condition for touch devices
}

function closeSearchBar() {
  isSearchBarToggledOn.value = false;
}

function openSearchBar() {
  isSearchBarToggledOn.value = true;
}

onMounted(() => {
  window.addEventListener('resize', onResize);
  isTouch.value = window.innerWidth <= 768; // Example condition for touch devices
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>
