<template>
  <pt-popup v-model="popupOpened" full-screen click-through-to-close>
    <div class="zoomable-image-popup">
      <picture-basic v-if="imageBindings?.src" v-bind="imageBindings" />
    </div>
  </pt-popup>
</template>

<script lang="ts" setup>
import { ZOOMABLE_IMAGE_POPUP_SLUG } from '@/constants/popups';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import { usePopups } from '~/composables/popup';
import PtPopup from '~/components/global/elements/PtPopup.vue';
import { usePopupsStore } from '~/stores/popups';

const { popupOpened } = usePopups(ZOOMABLE_IMAGE_POPUP_SLUG);
const popupStore = usePopupsStore();
const { zoomableImagePopupImage } = storeToRefs(popupStore);
const imageBindings = computed(() => ({ ...(zoomableImagePopupImage.value ?? {}), zoomable: false, heightContained: true }));
</script>
