<template>
  <transition name="fade-up">
    <div v-if="display" class="announcement-bar" role="banner" :class="rootClasses">
      <div class="announcement-bar__inner">
        <div class="announcement-bar__content">
          <h3 v-if="title" class="title is-3 has-marg-bottom-xxs">
            {{ title }}
          </h3>
          <bard :content="text" as-content primary-links class="announcement-bar__text" />
          <div v-if="cta" class="announcement-bar__cta has-marg-top-xxs">
            <pt-button-link :to="cta.link" type="white" @click="close">
              {{ cta.label }}
            </pt-button-link>
          </div>
        </div>
        <button class="announcement-bar__close" aria-label="Close Announcement" @click="close">
          <pt-icon name="close" :size="19" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import Bard from '~/components/content/Bard.vue';
import PtButtonLink from '~/components/global/elements/PtButtonLink.vue';
import { getSelectValue } from '@/utils/blocks';
import { useGlobalsStore } from '~/stores/globals';

const globalStore = useGlobalsStore();
const display = ref(false);
const { announcementBar } = storeToRefs(globalStore);
const cookieName = computed(() => announcementBar?.value?.handler || 'pt-announcement');
const enabled = computed(() => !!announcementBar?.value?.enabled);
const text = computed(() => (enabled.value ? announcementBar?.value?.text : null));
const hasCta = computed(() => enabled.value && announcementBar?.value?.cta);
const cta = computed(() => {
  if (!hasCta.value) return null;

  return {
    label: announcementBar?.value?.cta_label || 'Find out more',
    link: announcementBar?.value?.cta,
  };
});
const title = computed(() => (enabled.value ? announcementBar?.value.title : null));
const rootClasses = computed(() => {
  const classes = [];

  const bg = getSelectValue(announcementBar?.value?.background);

  if (bg) {
    classes.push('has-bg');
    classes.push(`has-bg-${bg}`);
  }

  return classes;
});

const shouldDisplay = () => {
  if (!enabled.value) return false;

  const appAnnouncementCookies = useCookie(cookieName.value);

  return !appAnnouncementCookies.value;
};

const displayNow = () => {
  display.value = true;
};

const close = () => {
  const appAnnouncementCookies = useCookie(cookieName.value);

  appAnnouncementCookies.value = '1';
  display.value = false;
};

onMounted(() => {
  if (shouldDisplay()) {
    setTimeout(() => {
      displayNow();
    }, 2000);
  }
});
</script>
