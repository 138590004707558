<template>
  <pt-popup v-model="popupOpened" :show-close-button-text="false" :min-height="false" paddingless wide>
    <div v-if="currentVideo" class="video-embed-popup">
      <div class="video-embed-popup__inner">
        <embed-block v-bind="embedBindings" />
      </div>
    </div>
  </pt-popup>
</template>

<script lang="ts" setup>
import { EMBED_VIDEO_POPUP } from '@/constants/popups';
import PtPopup from '@/components/global/elements/PtPopup.vue';
import EmbedBlock from '~/components/global/components/embed/EmbedBlock.vue';
import { usePopups } from '~/composables/popup';
import { useFeaturedVideoStore } from '~/stores/featured-video';

const { popupOpened } = usePopups(EMBED_VIDEO_POPUP);

const videoStore = useFeaturedVideoStore();
const { currentVideo } = storeToRefs(videoStore);

const embedBindings = computed(() => ({
  type: 'video',
  code: currentVideo.value,
}));
</script>
