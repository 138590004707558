<template>
  <div id="header" class="header" :class="{ 'is-hide-header-nav': !shouldHideNavigation }" @keydown.esc="close">
    <div class="header__inner container" :class="{ 'is-hide-navigation': !shouldHideNavigation }">
      <div class="header__logo-wrapper">
        <nuxt-link to="/" class="header__logo" aria-label="Public Trust Home" @click="scrollToTop">
          <logo150-years class="is-header" />
        </nuxt-link>
      </div>

      <template v-if="shouldHideNavigation">
        <div class="header__nav-toggler">
          <header-nav-toggler />
        </div>
        <div ref="headerNav" class="header__nav">
          <app-header-extras class="header__extras is-main" />
          <app-mega-nav v-if="navs.header" :nav="navs.header" class="header__mega-nav" />
          <app-header-extras-sec class="header__extras is-sec" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onUnmounted, computed } from 'vue';
import Logo150Years from '~/components/global/elements/Logo150Years.vue';
import AppMegaNav from '~/components/ui/layout/AppMegaNav.vue';
import AppHeaderExtras from '~/components/ui/layout/AppHeaderExtras.vue';
import AppHeaderExtrasSec from '~/components/ui/layout/AppHeaderExtrasSec.vue';
import HeaderNavToggler from '~/components/global/components/header-nav/HeaderNavToggler.vue';
import { useLayoutNavStore } from '~/stores/layout-nav';

const layoutNavStore = useLayoutNavStore();
const { displayNavigation } = usePages();
const navs = computed(() => layoutNavStore.navs);
const navOverlayOpen = computed(() => layoutNavStore.navOverlayOpen);
const headerNav = ref(null);
const shouldHideNavigation = ref(false);

onMounted(() => {
  shouldHideNavigation.value = displayNavigation.value;
});

watch(navOverlayOpen, (val) => {
  if (val) {
    document.documentElement.classList.add('is-nav-overlay-open');
  } else {
    scrollUnlock();
  }
});

watch(displayNavigation, (val) => {
  shouldHideNavigation.value = val;
});

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

function scrollUnlock() {
  document.documentElement.classList.remove('is-nav-overlay-open');
}

onUnmounted(() => {
  scrollUnlock();
});

const close = () => {
  if (layoutNavStore.navOverlayOpen) {
    layoutNavStore.toggleNavOverlay(false);
  }
};
</script>
