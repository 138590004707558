<template>
  <component :is="tag" v-bind="bindings" @click="onClick">
    <span :class="labelClass" :data-text="item.label">
      <pt-icon v-if="preIcon" :name="preIcon" :size="20" />
      {{ customLabel || item.label }}
    </span>
    <i v-if="hasDrop" :class="postIconClass">
      <pt-icon-menu-drop />
    </i>
    <i v-else-if="hasArrow" :class="postIconClass" aria-hidden="true">
      <pt-icon-chevron-right />
    </i>
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import isEmpty from 'lodash/isEmpty';
import PtIcon from '~/components/global/elements/PtIcon.vue';
import PtIconMenuDrop from '~/components/global/icons/PtIconMenuDrop.vue';
import PtIconChevronRight from '~/components/global/icons/PtIconChevronRight.vue';
import { slashed } from '~/utils/url';
import AppLink from '~/components/AppLink';
import ElementWrap from '~/components/global/elements/ElementWrap.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  hasDrop: Boolean,
  hasArrow: Boolean,
  labelClass: {
    type: [String, Array, Object],
    default: '',
  },
  customLabel: {
    type: String,
    default: '',
  },
  preIcon: {
    type: String,
    default: null,
  },
  postIconClass: {
    type: [String, Array, Object],
    default: '',
  },
  route: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['link-click']);
const isInternal = computed(() => !isEmpty(props.item.link_internal));
const isExternal = computed(() => !isEmpty(props.item.link_external));

const tag = computed(() => {
  return isInternal.value ? AppLink : ElementWrap;
});

const bindings = computed(() => {
  if (isInternal.value) {
    return {
      to: props.hasDrop ? '' : slashed(props.item.link_internal),
      class: {
        'is-current': props.hasDrop && props.route?.path?.startsWith(slashed(props.item.link_internal)),
      },
    };
  }

  if (isExternal.value) {
    return { tag: 'a', href: props.item.link_external };
  }

  return {
    tag: 'span',
  };
});

const onClick = (ev: any) => {
  emit('link-click', ev, props.item);
};
</script>
