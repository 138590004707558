<template>
  <pt-popup v-model="popupOpened" paddingless wide>
    <div class="book-apt-popup">
      <div class="book-apt-popup__cols">
        <div class="book-apt-popup__col is-details">
          <div class="book-apt-popup__details">
            <h3 class="book-apt-popup__details-title title is-1">Book an appointment</h3>
            <div v-if="contactPhone" class="book-apt-popup__details-contact has-marg-top-l">
              <a :href="contactPhoneHref" class="book-apt-popup__details-contact-link lead-label is-strong">
                <pt-icon name="phone" :size="18" />
                <span>{{ contactPhone }}</span>
              </a>
            </div>
            <div class="book-apt-popup__details-image has-marg-top-xl">
              <figure>
                <img src="/images/book-now.png" alt="Book Now Image" />
              </figure>
            </div>
          </div>
          <popup-book-blob class="book-apt-popup__blob" />
        </div>
        <div class="book-apt-popup__col is-form">
          <div class="book-apt-popup__form" style="width: 100%; height: 100%; margin: 0; padding: 0">
            <iframe
              src="https://outlook.office365.com/owa/calendar/BookingsPTNthIsld@publictrust.co.nz/bookings/"
              width="100%"
              height="100%"
              scrolling="yes"
              style="flex-grow: 1; border: none; margin: 0; padding: 0"
            />
          </div>
        </div>
      </div>
    </div>
  </pt-popup>
</template>

<script lang="ts" setup>
import { useGlobalsStore } from '~/stores/globals';
import PtIcon from '~/components/global/elements/PtIcon.vue';
import PopupBookBlob from '~/components/global/blobs/PopupBookBlob.vue';
import PtPopup from '~/components/global/elements/PtPopup.vue';
import { formatPhoneHref } from '~/utils/formatters';
import { usePopups } from '~/composables/popup';
import { BOOK_APPOINTMENT_POPUP_SLUG } from '~/constants/popups';

const { popupOpened } = usePopups(BOOK_APPOINTMENT_POPUP_SLUG);

const globalStore = useGlobalsStore();
const contactPhone = computed(() => globalStore.contact.phone || null);
const contactPhoneHref = computed(() => formatPhoneHref(contactPhone.value));
</script>
