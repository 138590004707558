<template>
  <div v-if="shouldHideNavigation" class="footer">
    <div class="footer__inner container">
      <div class="footer__cols">
        <div class="footer__col is-main">
          <div class="footer__nav-wrap is-touch">
            <h4 class="footer__title title is-6 is-strong">Get in touch</h4>
            <footer-nav :items="getInTouchNav" type="primary" class="footer__nav" />
          </div>
          <div class="footer__nav-wrap is-services">
            <h4 class="footer__title title is-6 is-strong">Our Products and Services</h4>
            <footer-nav :items="servicesNav" type="primary" class="footer__nav is-services" />
          </div>
          <div class="footer__extra">
            <h4 class="footer__title title is-6 is-strong">Public Trust</h4>
            <div class="footer__extra-navs">
              <footer-nav :items="ptNav1" type="secondary" class="footer__extra-nav" />
              <footer-nav :items="ptNav2" type="tetriary" class="footer__extra-nav" />
              <footer-nav :items="ptNav3" type="tetriary" class="footer__extra-nav" />
            </div>
          </div>
        </div>
        <div class="footer__col is-services">
          <h4 class="footer__title title is-6 is-strong">Our Products and Services</h4>
          <footer-nav :items="servicesNav" type="primary" class="footer__nav" />
        </div>
        <div class="footer__col is-socials">
          <footer-socials class="footer__socials" />
          <logo tag="a" to="/" class="footer__logo" aria-label="Public Trust Home" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import Logo from '~/components/global/elements/Logo.vue';
import FooterNav from '~/components/ui/layout/footer-nav/FooterNav.vue';
import FooterSocials from '~/components/ui/layout/footer-nav/FooterSocials.vue';
import { transformNavItem } from '@/utils/navs';
import { useLayoutNavStore } from '~/stores/layout-nav';
import { usePages } from '~/composables/pages';

const layoutNavStore = useLayoutNavStore();
const navs = computed(() => layoutNavStore.navs);
const { displayNavigation } = usePages();

const getNav = (handle) => {
  return navs.value && navs.value.footer && navs.value.footer[handle] ? navs.value.footer[handle].map(transformNavItem).filter((n) => n) : [];
};

const getInTouchNav = computed(() => getNav('get_in_touch'));
const ptNav1 = computed(() => getNav('public_trust_1'));
const ptNav2 = computed(() => getNav('public_trust_2'));
const ptNav3 = computed(() => getNav('public_trust_3'));
const servicesNav = computed(() => getNav('products_and_services'));
const shouldHideNavigation = ref(false);

onMounted(() => {
  shouldHideNavigation.value = displayNavigation.value;

  if (window.ds07o6pcmkorn) {
    const frameName = new window.ds07o6pcmkorn({
      openElementId: '#shielded-logo',
      modalID: 'modal',
    });
    frameName.init();
  }
});

watch(displayNavigation, (val) => {
  shouldHideNavigation.value = val;
});
</script>
