<template>
  <div class="main-nav-sub" role="none">
    <div class="main-nav-sub__inner container" role="none">
      <hr class="main-nav-sub__sep" role="presentation" />
      <header class="main-nav-sub__header">
        <h3 class="main-nav-sub__title" role="none">
          <MegaMenuItemLink :item="item" :has="false" role="menuitem" @link-click="$emit('link-click', $event)" />
        </h3>
        <button class="main-nav-sub__close" @click.prevent="$emit('close')">
          <span class="visually-hidden">Close sub-menu</span>
          <PtIcon name="close-bold" :size="18" aria-hidden="true" />
        </button>
      </header>

      <div class="main-nav-sub__cols" role="none">
        <div v-for="(child, index) in childLinks" :key="`child-item-${index}`" class="main-nav-sub__col" role="none">
          <MegaMenuItemLink
            :item="child"
            has-arrow
            class="link-primary"
            label-class="link-primary__label"
            post-icon-class="link-primary__arrow"
            role="menuitem"
            tabindex="0"
            @link-click="$emit('link-click', $event)"
          />
          <p v-if="child.description" class="main-nav-sub__description" role="presentation">
            {{ child.description }}
          </p>
          <ul v-if="child.sub_links && child.sub_links.length" class="main-nav-sub__items" role="menu">
            <li v-for="(subLink, subIndex) in child.sub_links" :key="`child-sub-item-${subIndex}`" class="main-nav-sub__item" role="none">
              <MegaMenuItemLink
                :item="subLink"
                class="link-secondary"
                label-class="link-secondary__label"
                role="menuitem"
                tabindex="0"
                @link-click="$emit('link-click', $event)"
              />
            </li>
          </ul>
        </div>

        <div class="main-nav-sub__col is-all">
          <MegaMenuItemLink
            :item="item"
            has-arrow
            class="link-primary"
            custom-label="See All"
            label-class="link-primary__label"
            post-icon-class="link-primary__arrow is-always"
            role="menuitem"
            tabindex="0"
            @link-click="$emit('link-click', $event)"
          />
        </div>
      </div>
    </div>
    <div class="main-nav-sub__shadow" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import MegaMenuItemLink from '~/components/ui/layout/mega-menu/MegaMenuItemLink.vue';
import PtIcon from '~/components/global/elements/PtIcon.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

defineEmits(['link-click', 'close']);

const childLinks = computed(() => {
  return Array.isArray(props.item.child_links) ? props.item.child_links : [];
});
</script>
