<template>
  <component :is="tag" class="footer-nav__item">
    <component :is="linkComponent" v-bind="linkBindings" class="footer-nav__link">
      {{ item.label }}
    </component>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { LooseObject } from '~/types/generic';
import AppLink from '~/components/AppLink';
import { slashed } from '~/utils/url';

const props = defineProps({
  tag: {
    type: String,
    default: 'li',
  },
  item: {
    type: Object as () => LooseObject,
    required: true,
  },
});

const linkComponent = computed(() => {
  return props.item.component || AppLink;
});

const linkBindings = computed(() => {
  const bindings = {
    ...props.item.bindings,
  };
  if (bindings.action) {
    bindings.href = '#';
  }
  if (bindings.to) {
    bindings.to = slashed(bindings.to);
  }
  return bindings;
});
</script>
