<template>
  <ul class="footer-socials">
    <li v-for="item in socialLinks" :key="`social-${item.key}`" class="footer-socials__item">
      <a :href="item.link" class="footer-socials__link" target="_blank" rel="nofollow noopener" :aria-label="getLabel(item.key)">
        <PtIcon :name="item.key" :size="30" />
      </a>
    </li>
    <li class="footer-socials__item">
      <a id="shielded-logo" href="#" class="footer-socials__link">
        <img alt="shielded" src="/images/shielded-logo.png" height="60" width="60" />
      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useGlobalsStore } from '~/stores/globals';
import PtIcon from '~/components/global/elements/PtIcon.vue';

const globals = useGlobalsStore();
const socialLinks = computed(() => globals.socialLinks);

function getLabel(key: string): string {
  return key.charAt(0).toUpperCase() + key.slice(1);
}
</script>
